import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { createProviders as createMsalProviders } from '@core/utils/msal';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) enableProdMode();

fetch('configuration/config.json')
  .then((response) => response.json())
  .then((config) => {
    const msalProviders = createMsalProviders(config);
    return platformBrowserDynamic([
      { provide: RUNTIME_CONFIGURATION, useValue: config },
      ...msalProviders,
    ]).bootstrapModule(AppModule);
  })
  .catch(console.error);
